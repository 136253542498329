import React from "react";
import {
	Spinner, SpinnerSize
} from '@fluentui/react';

export const Loader : React.FC<{}> = props => {

	return (
		<Spinner size={SpinnerSize.large} />
	);
}