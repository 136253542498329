import React, { useState, useEffect } from "react";
import {
	ComboBox, IComboBox, IComboBoxOption,
	TextField,
	PrimaryButton,
	DefaultButton,
	Dialog, DialogFooter, DialogType,
	IconButton,
	Icon,
	TooltipHost,
	MessageBar, MessageBarType,
} from '@fluentui/react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser"; //InteractionType

// import { msalInstance } from '../../index';
// import * as Helpers from '../../utils/helpers';
import { Loader, PeopleList, UpgradeDowngradePanel, ChangeUserCountPanel } from '../../components';
import * as authConfig from "../../configuration/authConfig";
import * as chitChattrApi from '../../services/chitchattrApiService';
import { ICcSubscription, LicenseAllocationType, LicenseStatus, PeopleEditorPeopleType, SubscriptionLevel } from '../../types';

import styles from '../../styles/shared.module.scss';

export function QuicklinksSubscriptionAdminPage(props: {}) {
	const { instance, inProgress } = useMsal();

	const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
	const msSubscriptionId = urlSearchParams.get("p") ?? '';
	// const wizardMode = urlSearchParams.get("w") === "y";

	// const [hasMgtScopes, setHasMGtScopes] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [availableSubscriptions, setAvailableSubscriptions] = useState<ICcSubscription[]>([]);
	const [selectedSubscription, setSelectedSubscription] = useState<ICcSubscription | null>(null);
	const [directoryLookupConsentGranted, setDirectoryLookupConsentGranted] = useState(false);
	const [ssoEnabled, setSsoEnabled] = useState(false);
	const [editingLabel, setEditingLabel] = useState(false);
	const [subscriptionLabel, setSubscriptionLabel] = useState('');
	const [userIds, setUserIds] = useState<string[]>([]);
	const [currentLicenseAllocationType, setCurrentLicenseAllocationType] = useState(LicenseAllocationType.FirstComeFirstServed);
	const [showUpgradeDowngradePanel, setShowUpgradeDowngradePanel] = useState(false);
	const [showChangeUserCountPanel, setShowChangeUserCountPanel] = useState(false);
	const [showChangeLicenseLevelDialog, setShowChangeLicenseLevelDialog] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [changeSubscriptionLevelPrefix, setChangeSubscriptionLevelPrefix] = useState('');
	const [showChangeLicenseCountDialog, setShowChangeLicenseCountDialog] = useState(false);
	const [currentLicenseUserCount, setCurrentLicenseUserCount] = useState(0);

	const upgradeDowngradeLabel = (selectedSubscription && selectedSubscription.subscriptionLevel === SubscriptionLevel.Enterprise) ? "Downgrade" : "Upgrade";

	// useEffect(() => {
	// 	instance.acquireTokenSilent({ // msalInstance
	// 		scopes: authConfig.minimalApiAndPplPickerScopes
	// 	})
	// 		.then(() => {
	// 			setHasMGtScopes(true);
	// 		})
	// 		.catch(error => {
	// 			if (!(error instanceof InteractionRequiredAuthError)) {
	// 				console.log(error);
	// 			}
	// 		})
	// });

	useEffect(() => {
		if (inProgress === InteractionStatus.None && isLoading) {
			chitChattrApi.getMyPaidSubscriptions("Quicklinks")
				.then(payload => {
					const subscriptions = payload.mySubscriptions as ICcSubscription[];
					setDirectoryLookupConsentGranted(payload.directoryLookupConsentGranted);
					setSsoEnabled(payload.ssoEnabled);
					setAvailableSubscriptions(subscriptions);
					if (subscriptions.length > 0) {
						const selectedSubscription = msSubscriptionId // if one is passed in via Querystring, use it, otherwise fall back to 1st
							? subscriptions.find(s => s.purchaseId === msSubscriptionId) ?? subscriptions[0] // might not exist for some reason, fall back
							: subscriptions[0];

						modifyStateFromSubscription(selectedSubscription);
					}
					setIsLoading(false);
				})
				.catch((e) => {
					if (e instanceof InteractionRequiredAuthError) {
						instance.acquireTokenRedirect({
							scopes: authConfig.minimalApiAndPplPickerScopes,
							account: instance.getActiveAccount() as AccountInfo
						});
					}
					setIsLoading(false);
				});
		}
	}, [instance, inProgress, isLoading, msSubscriptionId]);

	const modifyStateFromSubscription = (subscription: ICcSubscription): void => {
		setSelectedSubscription(subscription);
		setSubscriptionLabel(subscription.label);
		setUserIds(subscription.users.map(user => user.userId));
		setCurrentLicenseAllocationType(subscription.licenseAllocationType);
		const subscriptionLevelPrefix = (subscription.subscriptionLevel === SubscriptionLevel.Free || subscription.subscriptionLevel === SubscriptionLevel.Pro) ? "Upgrade" : "Downgrade";
		setChangeSubscriptionLevelPrefix(subscriptionLevelPrefix);
		setCurrentLicenseUserCount(subscription.numberOfUsersLicensed);
	}

	const subscriptionPicker_Change = async (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): Promise<void> => {
		const subscriptionId = option?.key as string;

		modifyStateFromSubscription(availableSubscriptions.find(s => s.sId === subscriptionId) as ICcSubscription);
	}

	const userSelectTypeButtonClick = async (newLicenseAllocationType: LicenseAllocationType): Promise<void> => {
		if (selectedSubscription) {
			selectedSubscription.licenseAllocationType = newLicenseAllocationType;
			setSelectedSubscription(selectedSubscription);
			setCurrentLicenseAllocationType(newLicenseAllocationType);
		}

		await saveChanges(selectedSubscription as ICcSubscription);
	}

	const getSwitchButtons = () => {

		const fcfsProps = {
			text: "Open",
			className: styles.switchButton,
			onClick: () => userSelectTypeButtonClick(LicenseAllocationType.FirstComeFirstServed),
			disabled: isSaving || currentLicenseAllocationType === LicenseAllocationType.Site || selectedSubscription?.licenseStatus !== LicenseStatus.Active,
		}
		const perUserProps = {
			text: "Selected Users",
			className: styles.switchButton,
			onClick: () => userSelectTypeButtonClick(LicenseAllocationType.PerUser),
			disabled: isSaving || currentLicenseAllocationType === LicenseAllocationType.Site || selectedSubscription?.licenseStatus !== LicenseStatus.Active,
		}
		// const siteLicenseProps = {
		// 	text: "All Users",
		// 	className: styles.switchButton,
		// 	disabled: true,
		// }

		return <>
			{currentLicenseAllocationType === LicenseAllocationType.FirstComeFirstServed ? <PrimaryButton {...fcfsProps} /> : <DefaultButton {...fcfsProps} />}
			{currentLicenseAllocationType === LicenseAllocationType.PerUser ? <PrimaryButton {...perUserProps} /> : <DefaultButton {...perUserProps} />}
			{/* {currentLicenseAllocationType === LicenseAllocationType.Site ? <PrimaryButton {...siteLicenseProps} /> : <DefaultButton {...siteLicenseProps} />} */}
		</>
	}

	const subscriptionLabel_Change = async (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): Promise<void> => {
		if (selectedSubscription) {
			selectedSubscription.label = subscriptionLabel;

			await saveChanges(selectedSubscription as ICcSubscription);
			let availableSubscriptionsTemp = availableSubscriptions;
			(availableSubscriptionsTemp.find(s => s.sId === selectedSubscription?.sId) as ICcSubscription).label = subscriptionLabel;
			setAvailableSubscriptions(availableSubscriptionsTemp);

			setEditingLabel(false);
		}
	}

	const hideUpgradePane = async (newSubscriptionLevel: SubscriptionLevel | null) : Promise<void> => {
		if (newSubscriptionLevel !== null)
			setShowChangeLicenseLevelDialog(true);
		else
			setShowUpgradeDowngradePanel(false);
	}
	
	const hideChangeUserCountPane = async (newUserCount: number | null) : Promise<void> => {
		if (newUserCount !== null)
		{
			setCurrentLicenseUserCount(newUserCount);
			setShowChangeLicenseCountDialog(true);
		}
		else
			setShowChangeUserCountPanel(false);
	}

	const saveChanges = async (subscription: ICcSubscription) : Promise<void> => {
		setIsSaving(true);
		chitChattrApi.updateSubscription("Quicklinks", subscription)
			.then(payload => {
				const updatedSubscription = payload.updatedSubscription as ICcSubscription;

				modifyStateFromSubscription(updatedSubscription);

				setIsSaving(false);
			})
			.catch((e) => {
				if (e instanceof InteractionRequiredAuthError) {
					instance.acquireTokenRedirect({
						scopes: authConfig.minimalApiScopes,
						account: instance.getActiveAccount() as AccountInfo
					});
				}
				setIsSaving(false);
			});
	}

	const changeSubscriptionLevel = () => {
		if (selectedSubscription) {
			const newLevel = selectedSubscription.subscriptionLevel === SubscriptionLevel.Pro ? SubscriptionLevel.Enterprise : SubscriptionLevel.Pro;

			setIsSaving(true);
			setShowChangeLicenseLevelDialog(false);

			chitChattrApi.changeSubscriptionLevel("Quicklinks", selectedSubscription.sId, newLevel)
				.then(payload => {
					const status = payload.status as string;

					if (status === "success") {
						setIsSaving(false);
					}
					// else
					// 	setApiErrorMessage(payload.errorMessage);
				})
				.catch((e: any) => {
					if (e instanceof InteractionRequiredAuthError) {
						instance.acquireTokenRedirect({
							scopes: authConfig.minimalApiScopes,
							account: instance.getActiveAccount() as AccountInfo
						});
						// } else {
						// 	setApiErrorMessage(e);
					}
					setIsSaving(false);
				});
		}
	}

	const changeLicenseCount_Click = () => {
		if (selectedSubscription) {
			setIsSaving(true);
			setShowChangeLicenseCountDialog(false);

			chitChattrApi.changeSubscriptionLicenseCount("Quicklinks", selectedSubscription.sId, currentLicenseUserCount)
				.then(payload => {
					const status = payload.status as string;

					if (status === "success") {
						setIsSaving(false);
					}
					// else
					// 	setApiErrorMessage(payload.errorMessage);
				})
				.catch((e: any) => {
					if (e instanceof InteractionRequiredAuthError) {
						instance.acquireTokenRedirect({
							scopes: authConfig.minimalApiScopes,
							account: instance.getActiveAccount() as AccountInfo
						});
						// } else {
						// 	setApiErrorMessage(e);
					}
					setIsSaving(false);
				});
		}
	}

	return (
		<>
			<UnauthenticatedTemplate>
				Warning message here about logging in...
			</UnauthenticatedTemplate>

			<AuthenticatedTemplate>

				{isLoading && (
					<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "75%" }}>
						<Loader />
						Loading subscriptions...
					</div>
				)}

				{!isLoading && availableSubscriptions.length === 0 && (
					<div style={{maxWidth: "800px", margin: "auto"}}>
						<p>
							You are not currently set as an owner for any Quicklinks subscriptions.
						</p>
						<p>
							If you are an Microsoft 365 Tenant or Teams Service Administator and your organization has one or more
							Quicklinks subscriptions, please click <a href={"/QuicklinksConsent?s=" + selectedSubscription?.sId}>here</a> to grant consent for us to verify your role. 
							Once that is complete you will automatically be returned to this page where you can administer your Quicklinks subscriptions
						</p>
						{/* 

						https://login.microsoftonline.com/common/adminconsent?client_id=b06275f4-55fe-4af3-9929-51831531f1e3&redirect_uri=https://hg-dev.chitchattr.com/QuicklinksPortalAdminConsentComplete&state=QL|a5b92033-e4d2-4bf5-99ee-9eb048d34b9b */}
					</div>
				)}

				{!isLoading && availableSubscriptions.length > 0 && selectedSubscription && (
					<div className={styles.subscriptionAdminContainer}>

						{availableSubscriptions.filter(s => s.licenseStatus !== LicenseStatus.Active).map(subscriptionWithError =>
							<MessageBar key={"errormsg-" + subscriptionWithError.sId} messageBarType={MessageBarType.blocked}>
								Your subscription named <b>{subscriptionWithError.label}</b> is currently <b>{LicenseStatus[subscriptionWithError.licenseStatus]}</b>. Please correct this urgently in order to continue using Quicklinks without limitations!
							</MessageBar>
						)}

						{!isLoading && !ssoEnabled && (
							<MessageBar messageBarType={MessageBarType.warning}>
								Your organisation does not have enhanced security enabled for Quicklinks, which is highly recommended to best secure your information. Please click <a href={"/QuicklinksConsent?s=" + selectedSubscription?.sId}>here</a> for more information.
							</MessageBar>
						)}
						{!isLoading && !directoryLookupConsentGranted && (
							<MessageBar messageBarType={MessageBarType.warning}>
								Your organisation does not have a recommended security setting enabled to unlock additional security features for Quicklinks. Please click <a href={"/QuicklinksConsent?s=" + selectedSubscription?.sId}>here</a> for more information.
							</MessageBar>
						)}

						<div className={styles.subscriptionAdminHeaderContainer}>
							<div>
								{!editingLabel
									? <div style={{ display: "flex", alignItems: "baseline" }}>
										<h2>
											{availableSubscriptions.length === 1
												? subscriptionLabel
												: <ComboBox
													style={{width: "294px"}}
													allowFreeform={false}
													autoComplete="off"
													options={availableSubscriptions.map(s => { return { key: s.sId, text: s.label ? s.label + (s.licenseStatus === LicenseStatus.Active ? "" : " (" + LicenseStatus[s.licenseStatus] + "!)") : s.sId } as IComboBoxOption })}
													onChange={subscriptionPicker_Change}
													selectedKey={selectedSubscription.sId}
												/>
											}
											<IconButton iconProps={{ iconName: "Edit" }} onClick={() => setEditingLabel(true)} />
										</h2>
										<TooltipHost content={"If you have multiple subscriptions you might want to give each of them a label to identify them more easily."}>
											<Icon iconName="Info" style={{ paddingLeft: "10px", fontSize: "1.1rem", cursor: "pointer" }} />
										</TooltipHost>
									</div>
									: <TextField
										value={subscriptionLabel}
										placeholder="Subscription label, e.g. 'Finance Team' or 'US Sales'"
										onChange={(e, newValue) => setSubscriptionLabel(newValue as string)}
										onBlur={subscriptionLabel_Change}
										disabled={isSaving}
									/>
								}
							</div>
							<div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>

								{isSaving && (
									<>
										<Loader /> <span style={{ padding: "0 40px 0 15px" }}>Saving...</span>
									</>
								)}

								<span style={{ marginRight: "20px" }}>Subscription level: <b>{selectedSubscription ? SubscriptionLevel[selectedSubscription.subscriptionLevel] : ""}</b></span>
								<PrimaryButton
									text={upgradeDowngradeLabel + " License"}
									disabled={isSaving || !selectedSubscription || selectedSubscription.licenseStatus !== LicenseStatus.Active}
									onClick={() => setShowUpgradeDowngradePanel(true)}
								/>
							</div>
						</div>

						<div className={styles.subscriptionAdminPeopleContainer}>
							<div>
								<PeopleList
									editorType={PeopleEditorPeopleType.User}
									subscription={selectedSubscription}
									saveChanges={saveChanges}
									saving={isSaving}
									disabled={!selectedSubscription || selectedSubscription.licenseStatus !== LicenseStatus.Active} />
							</div>

							<div>
								<PeopleList
									editorType={PeopleEditorPeopleType.Admin}
									subscription={selectedSubscription}
									saveChanges={saveChanges}
									saving={isSaving}
									disabled={!selectedSubscription || selectedSubscription.licenseStatus !== LicenseStatus.Active} />
							</div>
						</div>

						<div style={{ width: "90%", margin: "40px auto auto auto" }}>
							<div className={styles.switchButtonContainer}>
								<div style={{ marginRight: "20px" }}>License Allocation Type:</div>
								<div>
									{getSwitchButtons()}
									<TooltipHost content={"Aside from the users assigned above, do you want any remaining licenses to be assigned in an Open (first-come, first-served basis) manner or locked to only allowing the Specific Users assignments above."}>
										<Icon iconName="Info" style={{ paddingLeft: "10px", fontSize: "1.1rem", cursor: "pointer" }} />
									</TooltipHost>
								</div>
							</div>

							<div>
								{selectedSubscription && currentLicenseAllocationType !== LicenseAllocationType.Site && (
									<>
										<span style={{color: selectedSubscription.numberOfUsersLicensed - userIds.length > 0 ? "" : "darkorange"}}>{selectedSubscription.numberOfUsersLicensed - userIds.length} of {selectedSubscription.numberOfUsersLicensed} licenses remaining</span>
										<PrimaryButton
											style={{ marginLeft: "20px" }}
											text="Change license count"
											disabled={!selectedSubscription || selectedSubscription.licenseStatus !== LicenseStatus.Active}
											onClick={() => setShowChangeUserCountPanel(true)}
										/>
									</>
								)}
							</div>
						</div>

					</div>
				)}

				{/* {selectedSubscription.subscriptionLevel === SubscriptionLevel.Free
					? <>Ready to upgrade to a <a href="https://www.chitchattr.com/quicklinks/" target="_blank" rel="noreferrer">paid license</a>?</>
					: <>
						<div>License Level: {SubscriptionLevel[selectedSubscription.subscriptionLevel]}</div>
						<PrimaryButton
							text={upgradeDowngradeLabel + " License"}
							onClick={() => setShowUpgradeDowngradePanel(true)}
						/>
					</>
				} */}

				{selectedSubscription && showUpgradeDowngradePanel && (
					<UpgradeDowngradePanel subscription={selectedSubscription} hidePane={hideUpgradePane} />
				)}

				{selectedSubscription && showChangeUserCountPanel && (
					<ChangeUserCountPanel subscription={selectedSubscription} hidePane={hideChangeUserCountPane} />
				)}


				<Dialog
					hidden={!showChangeLicenseLevelDialog}
					onDismiss={() => setShowChangeLicenseLevelDialog(false)}
					dialogContentProps={{
						type: DialogType.normal,
						title: changeSubscriptionLevelPrefix + " License",
						closeButtonAriaLabel: 'Close',
						subText: `Are you sure you want to ${changeSubscriptionLevelPrefix} your license?`,
					}}
				>
					<DialogFooter>
						<DefaultButton onClick={() => setShowChangeLicenseLevelDialog(false)} text={`Never mind`} />
						<PrimaryButton className={styles.PrimaryButton} onClick={changeSubscriptionLevel} text={`Yes, go ahead`} />
					</DialogFooter>
				</Dialog>

				<Dialog
					hidden={!showChangeLicenseCountDialog}
					onDismiss={() => setShowChangeLicenseCountDialog(false)}
					dialogContentProps={{
						type: DialogType.normal,
						title: "Change User License Count",
						closeButtonAriaLabel: 'Close',
						subText: `Are you sure you want to change the number of users on your license?`,
					}}
				>
					<DialogFooter>
						<DefaultButton onClick={() => setShowChangeLicenseCountDialog(false)} text={`Never mind`} />
						<PrimaryButton disabled={!selectedSubscription || currentLicenseUserCount < selectedSubscription.users.length} className={styles.PrimaryButton} onClick={changeLicenseCount_Click} text={`Save Changes`} />
					</DialogFooter>
				</Dialog>

			</AuthenticatedTemplate>
		</>
	)
}