import React from 'react';
import { PrimaryButton } from '@fluentui/react';

import styles from '../styles/shared.module.scss';

import { ConsentBadge } from './consentBadge';

interface ConsentSectionProps {
	loading: boolean;
	title: React.ReactNode;
	description: React.ReactNode;
	consentGranted: boolean;
	buttonAction: () => Promise<void>;
}

export const ConsentSection: React.FC<ConsentSectionProps> = props => {
	return (
		<div className={styles.consentSection}>
			<ConsentBadge loading={props.loading} consentGranted={props.consentGranted} />
			{/* {props.loading
			? <Loader />
			: <ConsentBadge loading={props.loading} consentGranted={props.consentGranted} />
			} */}

			<div className={styles.consentSectionTextblock}>
				<h3>{props.title}</h3>
				{/* {(typeof props.description === 'string' || props.description instanceof String)
					? <span style={{fontSize: "1.2rem"}}>{props.description}</span>
					: props.description
				} */}
				<span style={{fontSize: "1.2rem"}}>{props.description}</span>
			</div>
			
			<div style={{paddingLeft: "10px"}}>
				<PrimaryButton
					text='Allow access'
					disabled={props.loading || props.consentGranted}
					onClick={props.buttonAction} />
			</div>
		</div>
	);
};