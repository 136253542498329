import React, { useEffect, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { Login } from '@microsoft/mgt-react';

import jwt_decode from "jwt-decode";

import * as authConfig from "../configuration/authConfig";
// import { msalInstance } from "../index";

import LoginTemplate from './loginTemplate';
import { Feedback } from './feedback';

import styles from '../styles/shared.module.scss';

import chitChattrLogoNoTitle from '../assets/images/chitChattrLogoNoTitle.svg';

interface PageLayoutProps {
	logoContents?: React.ReactNode;
	helpUrl?: string;
	children: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = props => {
	const { instance } = useMsal();

	const [tenantId, setTenantId] = useState('');
	const [aadObjectId, setAadObjectId] = useState('');
	const [userName, setUserName] = useState('');
	const [userEmail, setUserEmail] = useState('');

	const helpUrl = props.helpUrl ?? "https://docs.chitchattr.com";

	useEffect(() => {
		instance.acquireTokenSilent({ // msalInstance
			scopes: authConfig.minimalApiScopes
		})
			.then((authResult) => {
				const decodedToken = jwt_decode(authResult.accessToken) as any;
				setTenantId(decodedToken.tid);
				setAadObjectId(decodedToken.oid);
				setUserName(decodedToken.name);
				setUserEmail(decodedToken.upn);
			})
			.catch(() => {
			})
	});

	// const doLogin = async () => {
	// 	instance.loginRedirect(authConfig.redirectLoginRequest);
	// 	// instance.loginRedirect(authConfig.redirectLoginRequest);
	// }

	// https://stackoverflow.com/questions/66179070/microsoft-graph-toolkit-get-logged-in-user

	return (
		<div className={styles.pageContainer}>
			<div className={styles.headerContainer}>
				<div className={styles.header}>
					<div>
						{props.logoContents}
					</div>
					<Login className={styles.avatarContainer}>
						<LoginTemplate template="signed-in-button-content" />
					</Login>
				</div>
			</div>

			<AuthenticatedTemplate>
				<div className={styles.pageContentContainer}>
					{props.children}
				</div>
			</AuthenticatedTemplate>

			<UnauthenticatedTemplate>
				<div className={styles.pageContentContainer}>
					You are not logged in yet - please click the 'Sign in' button in the top corner.
				</div>
			</UnauthenticatedTemplate>

			<div className={styles.footerContainer}>
				<div className={styles.footer}>

					<div>
						<img alt="ChitChattr Logo - light background" src={chitChattrLogoNoTitle} style={{width: "37px"}} />
						
						<div className={styles.footerMadeByText}>
							made by &nbsp;
						</div>
						
						<a href="https://www.chitchattr.com" target="_blank" rel="noreferrer">
							ChitChattr
						</a>
					</div>

					<div>
						<div className={styles.footerHelpLink}><a href={helpUrl} target="_blank" rel="noreferrer">Get help</a></div>
						<Feedback
							aadObjectId={aadObjectId}
							tenantId={tenantId}
							userDisplayName={userName}
							userEmail={userEmail}
							productName="Client Portal"
							/>
					</div>
				
				</div>
			</div>
		</div>
	);
};