import React from 'react';
import { Icon } from '@fluentui/react';

import { Loader } from './loader';

import styles from '../styles/shared.module.scss';

interface ConsentBadgeProps {
	consentGranted: boolean;
	loading: boolean;
}

export const ConsentBadge: React.FC<ConsentBadgeProps> = props => {
	return (
		<div style={{ display: "flex", alignItems: "center"}} className={props.consentGranted ? styles.success500 : styles.neutral400}>
			<div>
				<Icon iconName="Shield" style={{fontSize: "4rem"}} /> 
			</div>
			{props.loading
			? <div style={{marginLeft: "-48px", marginRight: "25px", marginTop: "-10px"}}>
				<Loader />
			</div>
			: <div style={{marginLeft: "-67px"}}>
				<Icon iconName={props.consentGranted ? "StatusCircleCheckmark" : "StatusCircleBlock2"} style={{fontSize: "4.5rem", lineHeight: "2.9rem"}} />
			</div>
			}
		</div>
	);
};