import React from "react";
import {
	MessageBar, MessageBarType,
	Panel,
	// Label,
	PrimaryButton,
	// Spinner, SpinnerSize,
	// DefaultButton,
	// Dialog, DialogFooter, DialogType,
} from '@fluentui/react';

import { ICcSubscription, SubscriptionLevel } from '../types';

import styles from '../styles/shared.module.scss';

interface UpgradeDowngradePanelProps {
	subscription: ICcSubscription,
	hidePane:  (newSubscriptionLevel: SubscriptionLevel | null) => Promise<void>
}

export const UpgradeDowngradePanel: React.FC<UpgradeDowngradePanelProps> = props => {

	const titlePrefix = (props.subscription.subscriptionLevel === SubscriptionLevel.Free || props.subscription.subscriptionLevel === SubscriptionLevel.Pro) ? "Upgrade" : "Downgrade";
	const currentLicenseTitle = props.subscription.subscriptionLevel === SubscriptionLevel.Free ? "Free"
								: props.subscription.subscriptionLevel === SubscriptionLevel.Pro ? "Professional"
								: "Enterprise";

	const changeLevelButtonClicked = () => {
		const newLevel = props.subscription.subscriptionLevel === SubscriptionLevel.Pro ? SubscriptionLevel.Enterprise : SubscriptionLevel.Pro; 
		props.hidePane(newLevel);
	}

	const cancel = () => {
		props.hidePane(null);
	}

	return (
		<Panel
			headerText={titlePrefix + " License?"}
			isOpen={true}
			onDismiss={cancel}
			isLightDismiss={true}
			closeButtonAriaLabel="Close">

			<div className={styles.row}>
				You are currently on a{props.subscription.subscriptionLevel === SubscriptionLevel.Enterprise && (<>n</>)} <b>{currentLicenseTitle}</b> license. To find out more about the features of each license option,
				please click <a href="https://www.chitchattr.com/quicklinks/" target="_blank" rel="noreferrer">here</a>.
			</div>

			<div className={styles.row}>
				<div style={{ textAlign: "center", paddingTop: "20px" }}>
					{(props.subscription.subscriptionLevel === SubscriptionLevel.Free || props.subscription.subscriptionLevel === SubscriptionLevel.Enterprise) && (
						<PrimaryButton onClick={changeLevelButtonClicked}>{titlePrefix} To Pro</PrimaryButton>
					)}

					{(props.subscription.subscriptionLevel === SubscriptionLevel.Free || props.subscription.subscriptionLevel === SubscriptionLevel.Pro) && (
						<PrimaryButton onClick={changeLevelButtonClicked}>{titlePrefix} To Enterprise</PrimaryButton>
					)}
				</div>

				<p style={{paddingTop: "20px"}}>
					<MessageBar messageBarType={MessageBarType.info} className={styles.consentMessageBar}>
						<span style={{fontSize: "1rem", lineHeight: "1.3rem"}}>
							<b>PLEASE NOTE!</b><br/>
							Changing the license level requires us to communicate with Microsoft services in order to ensure that the billing information is updated accordingly. This will take a few moments to complete so you will be notified by email when it is complete.
						</span>
					</MessageBar>
				</p>
			</div>
		</Panel>
	)
}