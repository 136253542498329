import React, { useState, useEffect } from "react";

import * as chitChattrApi from '../../services/chitchattrApiService';
import { Loader } from '../../components';

// import styles from '../../shared.module.scss';

export function QuicklinksPortalAdminConsentComplete(props: {}) {

	const [isLoading, setIsLoading] = useState(true);
	const [msConsentConfirmed, setMsConsentConfirmed] = useState(false);

	useEffect(() => {
		(async () => {
			if (isLoading && !msConsentConfirmed) {
				const consentConfirmedResponse = await chitChattrApi.checkApiConsentConfirmed();

				setMsConsentConfirmed(consentConfirmedResponse.consentGranted);
				setIsLoading(false);
			}
		})();
	}, [isLoading, msConsentConfirmed]);

	return (
		<div>
			
				{isLoading && (
					<Loader />
				)}

				{!isLoading && (
					<>
						{msConsentConfirmed && (
							<>
								Consent granted, redirecting...
							</>
						)}
						{!msConsentConfirmed && (
							<>
								Error with consent...
							</>
						)}
					</>
				)}

		</div>
	)
}