import React from "react";
import { MgtTemplateProps } from "@microsoft/mgt-react";

import styles from './loginTemplate.module.scss'

export default function LoginTemplate(props: MgtTemplateProps) {

	// const { personDetails, personImage } = props.dataContext;
	const { personImage } = props.dataContext;

	return (
		<div>
			<img
				alt="My avatar"
				className={styles.myAvatar}
				src={personImage} />
		</div>
	);
}
//flyout-person-details