export enum SubscriptionLevel {
	None = "None",
	Free = "Free",
	Trial = "Trial",
	Pro = "Pro",
	Enterprise = "Enterprise",
}

export enum LicenseAllocationType {
	FirstComeFirstServed = "FirstComeFirstServed",
	PerUser = "PerUser",
	Group = "Group",
	Site = "Site",
}

export enum LicenseStatus {
	Active = "Active",
	Suspended = "Suspended",
	Terminated = "Terminated",
}

export enum MsProviderSkus {
	Pro250 = "ql-pro",
	Pro500 = "ql-pro-band2",
	Pro1000 = "ql-pro-band3",
	Ent250 = "ql-ent",
	Ent500 = "ql-ent-band2",
	Ent1000 = "ql-ent-band3",
}

export interface IMsSubscription {
	id: string;
	offerId: string;
	planId: string;
	subscriptionName: string;
	quantity: string;
}

export interface ICcSubscription {
	sId: string;
	label: string;
	admins: IAdmin[];
	users: IUser[];
	firstUseDate: Date;
	purchaseId?: any;
	numberOfUsersLicensed: number;
	subscriptionLevel: SubscriptionLevel;
	licenseAllocationType: LicenseAllocationType;
	licenseStatus: LicenseStatus;
	providerSku: string;
}

export interface IAdmin {
	userId: string;
	userEmail?: any;
	userName: string;
}

export interface IUser {
	userId: string;
	userName: string;
}