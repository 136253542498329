import React, { useState, useEffect, useCallback } from "react";
import {
	PrimaryButton,
	MessageBar, MessageBarType,
} from '@fluentui/react';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

import * as authConfig from "../../configuration/authConfig";

import * as chitChattrApi from '../../services/chitchattrApiService';
import { ConsentSection } from '../../components';

import styles from '../../styles/wizard.module.scss';

import quicklinksLogo from '../../assets/images/quicklinksLogoNoTitle.png';

export function QuicklinksConsentPage(props: {}) {
	const { inProgress, instance } = useMsal();
	const msalProgress = inProgress;

	const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
	const pause = urlSearchParams.get("pause") ?? '';
	const stateReturn = urlSearchParams.get("state") ?? '';
	const adminConsentReturn = urlSearchParams.get("admin_consent") === 'True';
	const errorReturn = urlSearchParams.get("error") ? true : false;
	const msSubscriptionId = urlSearchParams.get("p") ?? (stateReturn ? stateReturn.split("|")[0] : '');
	const justConsentedAppId = urlSearchParams.get("p") ?? (stateReturn ? stateReturn.split("|")[1] : '');
	const qlSubscriptionId = urlSearchParams.get("s");
	
	const [isLoadingProductConsent, setIsLoadingProductConsent] = useState(true);
	const [productConsentConfirmed, setProductConsentConfirmed] = useState(false);
	const [isLoadingApiConsent, setIsLoadingApiConsent] = useState(true);
	const [apiConsentConfirmed, setApiConsentConfirmed] = useState(false);
	const [proceedButtonText, setProceedButtonText] = useState("Proceed");

	useEffect(() => {
		(async () => {
			if (adminConsentReturn && !errorReturn) {

				if (justConsentedAppId === process.env.REACT_APP_QUICKLINKS_CLIENT_ID)
				{
					await chitChattrApi.enableProductSSO("Quicklinks");

					setProductConsentConfirmed(true);
					setIsLoadingProductConsent(false);
				}
			}
		})();
	}, [adminConsentReturn, errorReturn, justConsentedAppId]);

	useEffect(() => {
		(async () => {
			if (msalProgress === InteractionStatus.None && isLoadingProductConsent && !productConsentConfirmed) {
				try {
					const consentConfirmedResponse = await chitChattrApi.checkProductConsentConfirmed("Quicklinks");

					setProductConsentConfirmed(consentConfirmedResponse.success);
				} finally {
					setIsLoadingProductConsent(false);
				}
			}
		})();

		(async () => {
			if (msalProgress === InteractionStatus.None &&isLoadingApiConsent && !apiConsentConfirmed) {
				try {
					const consentConfirmedResponse = await chitChattrApi.checkApiConsentConfirmed();

					setApiConsentConfirmed(consentConfirmedResponse.consentGranted);
				} finally {
					setIsLoadingApiConsent(false);
				}
			}
		})();
	}, [isLoadingProductConsent, productConsentConfirmed, isLoadingApiConsent, apiConsentConfirmed, msalProgress]);

	const doLogin = async () => {
		instance.loginRedirect(authConfig.redirectLoginRequest);
		// instance.loginRedirect(authConfig.redirectLoginRequest);
	}

	const redirectForProductConsent = async () : Promise<void> => {
		const consentApiAppId = process.env.REACT_APP_QUICKLINKS_CLIENT_ID;
		const stateVar = encodeURIComponent(msSubscriptionId + `|` + consentApiAppId);

		document.location.href = `https://login.microsoftonline.com/common/adminconsent?client_id=${consentApiAppId}&state=${stateVar}&redirect_uri=` + encodeURIComponent(document.location.origin + document.location.pathname);
		// var a = document.createElement('a');
		// a.href = "https://app.chitchattr.com/strictSSO?productName=Quicklinks"
		// a.target = "_blank";
		// document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
		// a.click();
		// a.remove(); //afterwards we remove the element again
	}

	const redirectForBackendConsent = async () : Promise<void> => {
		// let queryParams = new URLSearchParams(window.location.search);
		// queryParams.set('pg', "3");
		// window.history.replaceState(null, "", "?" + queryParams.toString());
		// console.log(window.location.href);
		const consentApiAppId = process.env.REACT_APP_DIRECTORY_API_CLIENT_ID;
		const stateVar = encodeURIComponent(msSubscriptionId + `|` + consentApiAppId);

		document.location.href = `https://login.microsoftonline.com/common/adminconsent?client_id=${consentApiAppId}&state=${stateVar}&redirect_uri=` + encodeURIComponent(document.location.origin + document.location.pathname);
	}

	const proceedToWizard = useCallback(() => {
		if (!pause)
			if (msSubscriptionId)
				document.location.href = '/QuicklinksSubscriptionWizard?p=' + msSubscriptionId;
			else
				document.location.href = '/quicklinksSubscriptionAdmin?s=' + qlSubscriptionId;

		setProceedButtonText("Proceeding...");
	}, [pause, msSubscriptionId, qlSubscriptionId]);

	useEffect(() => {
		if (productConsentConfirmed && apiConsentConfirmed)
			proceedToWizard();
	}, [productConsentConfirmed, apiConsentConfirmed, proceedToWizard]);

	const portalConsentProps = {
		loading: msalProgress !== InteractionStatus.None,
		title: "Log in to this portal",
		description: "In order to protect your information we need to log you in and verify your identify as well as allow you to select teammates to grant them licenses. Please click the 'Allow access' button to continue.",
		buttonAction: doLogin,
	};

	return (
		<div className={styles.centerPanel}>
			
			<div className={styles.topLogoTitleArea}>
				<img alt="Quicklinks Logo" src={quicklinksLogo} />

				<h1>Before we begin...</h1>
			</div>

			<h3>Microsoft 365 Sign-In & Consent</h3>

			<UnauthenticatedTemplate>
				<ConsentSection
					consentGranted={false}
					{...portalConsentProps}
					/>

				<MessageBar messageBarType={MessageBarType.info} className={styles.consentMessageBar}>
					<span style={{fontSize: "1rem", lineHeight: "1.3rem"}}>
						<b>PLEASE NOTE!</b><br/>
						Depending your organization's settings, you may not have access to log in using the 'Allow access' button above.
						In this case, from the error page that will be shown, click the 'return' link to come back to this page and send a link to this page to your IT Administrator that they can perform all these activities on your behalf.
						After that you can return to this page to continue and begin configuring your subscription.
					</span>
				</MessageBar>

			</UnauthenticatedTemplate>

			<AuthenticatedTemplate>
				<ConsentSection
					consentGranted={true}
					{...portalConsentProps}
					/>
			</AuthenticatedTemplate>

			<h3 style={{margin: "28px 0 15px 0"}}>Administrator Consent</h3>
			<span style={{marginBottom: "20px"}}>
				Are you an Tenant Admin for your organization's Microsoft 365 Tenant? <br/>
				If so, please consider granting the consents below to unlock the full functionality of Quicklinks.
			</span>

			<ConsentSection
				loading={isLoadingProductConsent}
				consentGranted={productConsentConfirmed}
				title="Quicklinks application pre-consent"
				description={<>
					This pre-consent to allow your users to sign in automatically (SSO) to the Quicklinks application itself, enforcing the highest level of security for your users and data.
					<div style={{color: "orangered", marginTop: "8px"}}>Please be sure to select the <b>'Consent on behalf of your organization'</b> option in the step that follows.</div>
				</>}
				buttonAction={redirectForProductConsent}
				/>

			<ConsentSection
				loading={isLoadingApiConsent}
				consentGranted={apiConsentConfirmed}
				title="Tenant User Access"
				description="This consent allows the ChitChattr API to call your organization's directory to verify your or a colleague's role as a Tenant or Teams administrator and to more accurately determine Channel owners to allow them to configure advanced Quicklinks settings."
				buttonAction={redirectForBackendConsent}
				/>

			<PrimaryButton
				disabled={productConsentConfirmed && apiConsentConfirmed}
				onClick={proceedToWizard}
				>{proceedButtonText}</PrimaryButton>

		</div>
	)
}