import React from 'react';
import {
	PrimaryButton,
	// Spinner, SpinnerSize,
} from '@fluentui/react';

import { StepWizardChildProps } from "react-step-wizard";

export interface CustomerPortalStepWizardChildProps extends StepWizardChildProps {
	saving: boolean;
	canProceed: boolean;
}

export const WizardStateManager: React.FC<Partial<CustomerPortalStepWizardChildProps>> = props => (
	<div style={{paddingTop: "20px"}}>
        { (props.currentStep && props.totalSteps && props.currentStep > 1 && props.currentStep <= props.totalSteps - 1) &&
            <PrimaryButton disabled={props.saving} onClick={props.previousStep} style={{marginRight: "15px"}}>Previous</PrimaryButton>
        }
        { props.currentStep && props.totalSteps && props.currentStep < props.totalSteps ?
            <PrimaryButton disabled={props.saving || !props.canProceed} onClick={props.nextStep}>Continue</PrimaryButton>
            : <></>
        }
        {/* <div style={{ fontSize: '21px', fontWeight: '200' }}>
            <h4>Other Functions</h4>
            <div>Current Step: {props.currentStep}</div>
            <div>Total Steps: {props.totalSteps}</div>
            <button className='btn btn-block btn-default' onClick={props.firstStep}>First Step</button>
            <button className='btn btn-block btn-default' onClick={props.lastStep}>Last Step</button>
            <button className='btn btn-block btn-default' onClick={() => props.goToStep(2)}>Go to Step 2</button>
        </div> */}
    </div>
);