import React from "react";
import {
	Icon,
} from '@fluentui/react';

import { WizardStateManager } from '..';

import { LicenseAllocationType, SubscriptionStepWizardChildProps } from '../../types';

import styles from '../../styles/wizard.module.scss';

export const LicenseAssignmentTypeStep : React.FC<Partial<SubscriptionStepWizardChildProps>> = ({ ...props }) => {
// export const LicenseAssignmentTypeStep = (props:any) => {

	const changeLicenseAssignmentType = async (newType:LicenseAllocationType) : Promise<void> => {
		let subscription = props.subscription;
		if (subscription && props.saveChanges)
		{
			subscription.licenseAllocationType = newType;
			props.saveChanges(subscription);
		}
	}

	return (
		<div className={styles.wizardStep}>
			
			<div>
				<h3>What type of license allocation would you like for Quicklinks?</h3>

				<span>This will control how new users get licenses for Quicklinks</span>

				<div className={styles.licenseAssignmentTypeOuterContainer}>
					
					<button
						disabled={props.saving}
						className={`${styles.licenseAssignmentTypeContainer} ${props.subscription?.licenseAllocationType === LicenseAllocationType.FirstComeFirstServed ? styles.active : ""}`}
						onClick={() => changeLicenseAssignmentType(LicenseAllocationType.FirstComeFirstServed)}>
						<div className={styles.licenseAssignmentGraphicContainer}><Icon iconName="CoffeeScript" className={styles.licenseAssignmentGraphic} /></div>
						<div className={styles.licenseAssignmentTitle}>Open</div>
						<div className={styles.licenseAssignmentDescription}>Licenses are assigned to users on a first-come, first-served basis</div>
					</button>
					
					<button
						disabled={props.saving}
						className={`${styles.licenseAssignmentTypeContainer} ${props.subscription?.licenseAllocationType === LicenseAllocationType.PerUser ? styles.active : ""}`}
						onClick={() => changeLicenseAssignmentType(LicenseAllocationType.PerUser)}>
						<div className={styles.licenseAssignmentGraphicContainer}><Icon iconName="PeopleAdd" className={styles.licenseAssignmentGraphic} /></div>
						<div className={styles.licenseAssignmentTitle}>Specific users</div>
						<div className={styles.licenseAssignmentDescription}>You need to specifically assign a license to each user before they can access Quicklinks</div>
					</button>

					{/* <button
						disabled={props.saving}
						className={`${styles.licenseAssignmentTypeContainer} ${props.subscription?.licenseAllocationType === LicenseAllocationType.Group ? styles.active : ""}`}
						onClick={() => changeLicenseAssignmentType(LicenseAllocationType.Group)}>
						Team
					</button> */}

				</div>
			</div>

            <WizardStateManager {...props}  />
		</div>
	)
}