import React, { useState, useEffect, useCallback } from "react";
import {
	Spinner, SpinnerSize,
} from '@fluentui/react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";

import StepWizard from "react-step-wizard";

import * as authConfig from "../../configuration/authConfig";	
import * as chitChattrApi from '../../services/chitchattrApiService';
import { ICcSubscription, LicenseAllocationType } from '../../types';
import { WizardNavigator } from '../../components';
import { SubscriptionNameStep, LicenseAssignmentTypeStep, AssignUsersStep, AssignAdminsStep, ThankYouStep } from '../../components/quicklinksWizard';
import { Loader } from '../../components';

import quicklinksLogo from '../../assets/images/quicklinksLogoNoTitle.png';

import styles from '../../styles/wizard.module.scss';

export function QuicklinksSubscriptionWizardPage(props: {}) {
	const { instance, inProgress } = useMsal();

	const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
	const stateReturn = urlSearchParams.get("state") ?? '';
	const msSubscriptionId = urlSearchParams.get("p") ?? (stateReturn ? stateReturn.split("|")[1] : '');
	const wizardStep = urlSearchParams.get("step") ? parseInt(urlSearchParams.get("step") as string) : undefined;

	const [isLoading, setIsLoading] = useState(true);
	const [hasMultipleSubscriptions, setHasMultipleSubscriptions] = useState(false);
	const [subscription, setSubscription] = useState<ICcSubscription | null>(null);
	const [isSaving, setIsSaving] = useState(false);
	const [wizardCanProceed, setWizardCanProceed] = useState(false);
	const [currentWizardStep, setCurrentWizardStep] = useState(1);
	
	useEffect(() => {
		if (inProgress === InteractionStatus.None && isLoading) {
			chitChattrApi.getMyPaidSubscriptions("Quicklinks")
				.then(payload => {
					const subscriptions = payload.mySubscriptions as ICcSubscription[];
					if (subscriptions.length > 0) {
						const selectedSubscription = subscriptions.find(s => s.purchaseId === msSubscriptionId);

						if (selectedSubscription)
						{
							setSubscription(selectedSubscription);
							setHasMultipleSubscriptions(payload.orgHasMultiplePaidSubscriptions);
							
							if (payload.orgHasMultiplePaidSubscriptions && !selectedSubscription.label)
								setWizardCanProceed(false);
							else
								setWizardCanProceed(true);
						}
					}
					setIsLoading(false);
				})
				.catch((e) => {
					if (e instanceof InteractionRequiredAuthError) {
						instance.acquireTokenRedirect({
							scopes: authConfig.minimalApiAndPplPickerScopes,
							account: instance.getActiveAccount() as AccountInfo
						});
					}
					setIsLoading(false);
				});
		}
	}, [instance, inProgress, isLoading, msSubscriptionId]);

	const onStepChange = (stepChange: {previousStep: number, activeStep: number}) : void => {
		if (stepChange.activeStep === (hasMultipleSubscriptions ? 5 : 4))
		{
			console.log("redirect user?");
		}
		setCurrentWizardStep(stepChange.activeStep);
	}

	const saveChanges = useCallback(async (subscription: ICcSubscription): Promise<void> => {
		setIsSaving(true);
		chitChattrApi.updateSubscription("Quicklinks", subscription)
			.then(payload => {
				const updatedSubscription = payload.updatedSubscription as ICcSubscription;

				setSubscription(updatedSubscription);

				setIsSaving(false);

				if (currentWizardStep === 1 && updatedSubscription.label)
					setWizardCanProceed(updatedSubscription.label ? true : false);
			})
			.catch((e) => {
				if (e instanceof InteractionRequiredAuthError) {
					instance.acquireTokenRedirect({
						scopes: authConfig.minimalApiScopes,
						account: instance.getActiveAccount() as AccountInfo
					});
				}
				setIsSaving(false);
			});
	}, [instance, currentWizardStep]);
	
	const getWizardSteps = useCallback(() => {

		const wizardChildProps = {
			subscription: subscription as ICcSubscription,
			saveChanges: saveChanges,
			isSaving: isSaving,
			canProceed: wizardCanProceed
		}

		if (subscription)
		{
			let baseSteps = [
				<LicenseAssignmentTypeStep key="LicenseAssignmentTypeStep" {...wizardChildProps} />,
			]

			if (subscription.licenseAllocationType === LicenseAllocationType.PerUser)
				baseSteps.push(<AssignUsersStep key="AddUsersStep" {...wizardChildProps} />);

			baseSteps = [
				...baseSteps,
				<AssignAdminsStep key="AssignAdminsStep" {...wizardChildProps} />,
				<ThankYouStep key="ThankYouStep" {...wizardChildProps} />,
			];

			if (hasMultipleSubscriptions)
			{
				return [
					<SubscriptionNameStep key="SubscriptionNameStep" {...wizardChildProps} />,
					...baseSteps
				];
			} else {
				return baseSteps;
			}
		}

	}, [hasMultipleSubscriptions, subscription, isSaving, wizardCanProceed, saveChanges]);

	return (
		<div className={styles.centerPanel}>
			
			<div className={styles.topArea}>
				<div className={styles.topLogoTitleArea}>
					<img alt="Quicklinks Logo" src={quicklinksLogo} />

					<h1>Quicklinks setup</h1>
				</div>
				<div style={{height: "30px", display: "flex", alignItems: "center", paddingTop: "20px"}}>
					{isSaving && (
						<>
							<Spinner size={SpinnerSize.large} style={{paddingRight: "20px"}} /> Saving...
						</>
					)}&nbsp;
				</div>
			</div>

			<UnauthenticatedTemplate>
				Not logged in, unable to continue...
			</UnauthenticatedTemplate>

			<AuthenticatedTemplate>

			{isLoading && (
				<div style={{textAlign: "center"}}>
					<Loader /> Loading subscription details...
				</div>
			)}

			{subscription && (
				<StepWizard
					className={styles.wizardStepsContainer}
					initialStep={wizardStep}
					onStepChange={onStepChange}
					transitions={isSaving ? {} : undefined}
					nav={<WizardNavigator />}>
					{getWizardSteps()}
				</StepWizard>
			)}

			</AuthenticatedTemplate>
		</div>
	)
}