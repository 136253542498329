import React from "react";
import {
	BrowserRouter, Routes, Route
} from "react-router-dom";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ThemeProvider, createTheme, initializeIcons } from '@fluentui/react';

// import { CustomNavigationClient } from "./utils/NavigationClient";

import { PageLayout, WizardLayout } from './components';

import {
	// HomePage,
	SubscriptionWelcomePage,
	QuicklinksWelcomePage,
	QuicklinksConsentPage,
	QuicklinksSubscriptionWizardPage,
	QuicklinksSubscriptionAdminPage,
	QuicklinksPortalAdminConsentComplete,
} from './pages';

import quicklinksLogo from './assets/images/quicklinksLogoNoTitle.png';

// import logo from './logo.svg';
import './App.css';

function App(props:{publicClientApplication: IPublicClientApplication}) {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    // const history = useHistory();
    // const navigationClient = new CustomNavigationClient(history);
    // pca.setNavigationClient(navigationClient);

	initializeIcons();

	const myTheme = createTheme({
		palette: {
		  themePrimary: '#17364f',
		  themeLighterAlt: '#030609',
		  themeLighter: '#050c12',
		  themeLight: '#d0d7dc',
		  themeTertiary: '#0a1824',
		  themeSecondary: '#f2ae49',
		  themeDarkAlt: '#0f2435',
		  themeDark: '#122a3e',
		  themeDarker: '#143046',
		  neutralLighterAlt: '#faf9f8',
		  neutralLighter: '#f3f2f1',
		  neutralLight: '#edebe9',
		  neutralQuaternaryAlt: '#e1dfdd',
		  neutralQuaternary: '#ffffff',
		  neutralTertiaryAlt: '#c8c6c4',
		  neutralTertiary: '#08121b',
		  neutralSecondary: '#0a1824',
		  neutralPrimaryAlt: '#0d1e2c',
		  neutralPrimary: '#17364f',
		  neutralDark: '#122a3e',
		  black: '#143046',
		  white: '#ffffff',
		}});

	// might have to get from url #, for different apps...
	// const authenticationService = useMemo(() => new AuthenticationService(process.env.REACT_APP_LICENSE_CLIENT_ID as string), []);

	// useEffect(() => {
	// 	authenticationService.handleRedirectPromise(); // we might be returning from an auth call in the main tab/page, so just implement this - it will complete the auth if needed, and redirect to the relevant page
	// }, [authenticationService]);

	const quicklinksHeaderContent = <div style={{display: "flex", paddingTop: "6px"}}>
										<img alt="Quicklinks Logo" src={quicklinksLogo} />
										<h1>Quicklinks</h1>
									</div>;

	const helpUrlBase = "https://docs.chitchattr.com/docs/quicklinks/";
	const consentHelpUrl = helpUrlBase + "subscription-management/consent";
	const wizardHelpUrl = helpUrlBase + "subscription-management/consent";
	const qlSubscriptionHelpUrl = helpUrlBase + "subscription-management/quicklinks-subscription-admin";

	return (
		<ThemeProvider applyTo="body" theme={myTheme} style={{ height: "100%" }}>
			<div className="App">
				<MsalProvider instance={props.publicClientApplication}>
					<BrowserRouter>
						<Routes>
							<Route index element={<PageLayout logoContents={quicklinksHeaderContent}><QuicklinksSubscriptionAdminPage /></PageLayout>} />
							<Route path="SubscriptionWelcome" element={<PageLayout><SubscriptionWelcomePage /></PageLayout>} />
							<Route path="QuicklinksWelcome" element={<WizardLayout showFooter={false}><QuicklinksWelcomePage /></WizardLayout>} />
							<Route path="QuicklinksConsent" element={<WizardLayout showFooter={true} helpUrl={consentHelpUrl}><QuicklinksConsentPage /></WizardLayout>} />
							<Route path="QuicklinksSubscriptionWizard" element={<WizardLayout showFooter={true} helpUrl={wizardHelpUrl}><QuicklinksSubscriptionWizardPage /></WizardLayout>} />
							<Route path="QuicklinksSubscriptionAdmin" element={<PageLayout logoContents={quicklinksHeaderContent} helpUrl={qlSubscriptionHelpUrl}><QuicklinksSubscriptionAdminPage /></PageLayout>} />
							<Route path="QuicklinksPortalAdminConsentComplete" element={<PageLayout><QuicklinksPortalAdminConsentComplete /></PageLayout>} />
						</Routes>
					</BrowserRouter>
				</MsalProvider>
			</div>
		</ThemeProvider>
	);
}

export default App;