import React, { useState } from "react";
import {
	Panel,
	Label,
	TextField,
	PrimaryButton,
	DefaultButton,
} from '@fluentui/react';

import styles from '../styles/shared.module.scss';

interface IFeedbackProps { // extends React.HTMLAttributes<HTMLElement> {
	productName: string;
	tenantId?: string;
	aadObjectId?: string;
	userEmail?: string;
	userDisplayName?: string;
	// children: React.ReactNode
}

export const Feedback : React.FC<IFeedbackProps> = (props) => {

	const sendFeedbackButtonDefaultText = "Send feedback";
	const [showPanel, setShowPanel] = useState(false);
	const [feedbackBody, setFeedbackBody] = useState('');
	const [feedbackBodyErrorMessage, setFeedbackBodyErrorMessage] = useState('');
	const [isSending, setIsSending] = useState(false);
	const [sendFeedbackButtonText, setSendFeedbackButtonText] = useState(sendFeedbackButtonDefaultText);
	const [responseMessage, setResponseMessage] = useState('');
	const [showValidationErrors, setShowValidationErrors] = useState(false);

	const validate = (): boolean => {
		let valid = true;

		setFeedbackBodyErrorMessage('');

		if (!feedbackBody) { valid = false; setFeedbackBodyErrorMessage("Please enter some actual feedback to send us"); }

		return valid;
	}

	const sendFeedback = async () => {
		setShowValidationErrors(true); // show any from now on

		if (validate()) {
			setIsSending(true);
			setSendFeedbackButtonText("Sending...");
		
			try {

				await fetch(`${process.env.REACT_APP_API_URLBASE}/Feedback/Add?code=oIC9YT2NOPvWIaa2GGwn6mRHqUjG4e8YA6zQTeqZxRiJAzFuf-4y7A==`, {
					method: 'POST',
					body: JSON.stringify({
						productName: props.productName,
						tenantId: props.tenantId,
						userId: props.aadObjectId,
						name: props.userDisplayName,
						email: props.userEmail,
						feedbackBody: feedbackBody
					})
				});

				setResponseMessage("Thank you for helping us to improve!");
				setFeedbackBody('');
				setShowValidationErrors(false);

				window.setTimeout(() =>{
					closePanel();
				}, 1000);

			} catch {

			}

			setIsSending(false);
			setSendFeedbackButtonText(sendFeedbackButtonDefaultText);
		}
	}

	const closePanel = () => {
		setShowValidationErrors(false);
		setShowPanel(!showPanel);
		setResponseMessage('');
	}

	return (
		<div>
			<PrimaryButton
				style={{height: "27px", padding: "0 11px"}}
				onClick={closePanel}>Give feedback</PrimaryButton>

			{showPanel && (
				<Panel
					headerText="Send feedback"
					isOpen={true}
					onDismiss={closePanel}
					isLightDismiss={true}
					closeButtonAriaLabel="Close">

				<div className={styles.row}>
					<Label style={{fontWeight: "normal"}}>Having a problem with this portal or have a suggestion or idea? We'd love to hear from you!</Label>
					<TextField
						multiline
						placeholder="Enter feedback or suggestions here..."
						value={feedbackBody}
						onChange={(e, value) => { setFeedbackBody(value as string); validate(); }}
						errorMessage={showValidationErrors ? feedbackBodyErrorMessage : ''}
					/>
				</div>

				<div className={styles.row}>
					<div style={{ textAlign: "right", paddingTop: "20px" }}>
						
						<DefaultButton onClick={closePanel}>Cancel</DefaultButton>

						<PrimaryButton
							primary
							className={styles.PrimaryButton}
							style={{ marginLeft: "15px" }}
							onClick={sendFeedback}
							disabled={isSending}>
								{sendFeedbackButtonText}&nbsp;
							</PrimaryButton>


					</div>
				</div>

				<div className={styles.row}>
					<Label style={{color: "green"}}>{responseMessage}</Label>
				</div>

			</Panel>
			)}
		</div>
	)
}