import { Configuration, RedirectRequest, SilentRequest } from "@azure/msal-browser";

export const msClientId = process.env.REACT_APP_LICENSE_CLIENT_ID as string;

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: msClientId,
        // authority: "https://login.windows-ppe.net/common",
        // redirectUri: "/",
        postLogoutRedirectUri: "/",
		redirectUri: window.location.origin,
		navigateToLoginRequestUrl: true,
    },
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: true
	}
};

const myApiScope = `api://${window.location.hostname}/${msClientId}/User.Verify`;

export const defaultScopes = [
	'User.Read',
]

export const minimalApiScopes = [
	myApiScope,
	'User.Read',
	'email',
	'openid',
	'offline_access',
	'profile',
]

// export const consentApiScopes = [
// 	myApiScope,
// 	'User.Read',
// 	'email',
// 	'openid',
// 	'offline_access',
// 	'profile',
// 	'Directory.Read.All',
// ]

export const mgtPeopleScopes = [
	'User.ReadBasic.All',
	'People.Read.All',
	// 'People.Read',
	// 'Group.Read.All',
	// 'Presence.Read.All',
	// 'Contacts.Read'
]

export const mgtPeoplePickerScopes = [
	'People.Read',
	'openid',
	'offline_access',
	'profile',
]

export const mgtGroupScopes = [
	'Group.Read.All',
	// 'People.Read',
	// 'People.Read.All',
	// 'Presence.Read.All',
	// 'User.ReadBasic.All',
	// 'Contacts.Read'
]

export const minimalApiAndPplPickerScopes = [
	...minimalApiScopes,
	...mgtPeopleScopes,
]

export const mgtScopes = [
	...mgtPeopleScopes,
	...mgtGroupScopes,
]

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const redirectLoginRequest: RedirectRequest = { // PopupRequest
    scopes: minimalApiAndPplPickerScopes, //["User.Read"]
};

export const silentLoginRequest: SilentRequest = { // PopupRequest
    scopes: minimalApiScopes, //["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};
