import { minimalApiScopes, minimalApiAndPplPickerScopes } from "../configuration/authConfig";
import { msalInstance } from "../index";

import { IAdmin, ICcSubscription, IUser, SubscriptionLevel } from '../types';

const urlBase = process.env.REACT_APP_API_URLBASE as string;

const apiCodes = {
	ChangeSubscriptionLevel: "iGvRwENSfAehFlXzeHgNOG7QxM6jW1oslH0RHyWmTL9LAzFudADOAg==",
	ChangeSubscriptionLicenseCount: "dONKrtZXuUo7LyYKD2WSDRosHsS8OKzN4WC4WBZ4Ly2VAzFuJpibbA==",
	CheckApiConsentConfirmed: "1SjteiGoh2X_8Rd2yMRvtNeNlOxfCzzI3uq-lPZX9W9ZAzFuKYel3g==",
	CheckSSO: "NOq3xYUd1MXw5t-d0Mmd-1Wi-mwCbhmyPuL7h-nq6VvYAzFuJmUEew==",
	ConfirmMsSubscription: "0ZVPWraAiyhrdpAPviJnfvFq3QY1iwDnUjIFR4SroU6GAzFuBhfJIA==",
	EnableSsoProduct: "_PQAyG2AhwYrVzE099TvDI7-Vi-y3OaYay4vGxnMAnRSAzFuS-MYOg==",
	GetMsSubscription: "z23t0EZCflWyV2QfETX8g6VR2tsJfYwJYugeEGVv1qnFAzFu3pqT8Q==",
	GetMyPaidSubscriptions: "nZVYMp4NS9RyVu-_Ok8_u0EuG5g7XL8u54MRhl-uU74gAzFucN_aSw==",
	UpdateSubscription: "yMQTzdBVJbjKKuKqNI1O0EVTf46ErfzB7YpuO73JL-diAzFu5HYulg==",
}

const callChitChattrApi = async (url:string, method?: string, body: any = null, requireToken: boolean = true, scopes: string[] = minimalApiScopes) : Promise<any> => {
	const account = msalInstance.getActiveAccount();
    if (!account && requireToken) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const headers = new Headers();

	if (account) // won't get this far is no account and requireToken is set
	{
		const response = await msalInstance.acquireTokenSilent({
			scopes: scopes,
			account: account
		});
	
		const bearer = `Bearer ${response.accessToken}`;
	
		headers.append("Authorization", bearer);	
	}

    const options = {
        method: method ?? "GET",
        headers: headers,
		body: body ? JSON.stringify(body) : null,
    };

    return fetch(urlBase + url, options)
        .then(response => response.json())
        .catch(error => { console.log(error); throw error });
}

export async function getMyPaidSubscriptions (productName: string) {
   return await callChitChattrApi(`/CustomerPortal/GetMyPaidSubscriptions?code=${apiCodes.GetMyPaidSubscriptions}&productName=${productName}`, "GET", null, true, minimalApiAndPplPickerScopes);
}

export async function updateSubscription (productName: string, subscription: ICcSubscription) {

	// clone and strip of PII
	let subscriptionCopy = JSON.parse(JSON.stringify(subscription)) as ICcSubscription;
	subscriptionCopy.users = subscription.users.map(user => { return {userId: user.userId, userName: ''} as IUser});
	subscriptionCopy.admins = subscription.admins.map(admin => { return {userId: admin.userId, userEmail: admin.userEmail, userName: ''} as IAdmin});

	return await callChitChattrApi(`/CustomerPortal/UpdateSubscription?code=${apiCodes.UpdateSubscription}&productName=${productName}`, "POST", subscription);
}

export async function getMsSubscription (msSubscriptionToken: string) {
	return await callChitChattrApi(`/CustomerPortal/GetMsSubscription?code=${apiCodes.GetMsSubscription}&msToken=${encodeURIComponent(msSubscriptionToken)}`, "GET", null, false);
}

export async function confirmMsSubscription (productName: string, msSubscriptionId: string, planId: string, quantity: string) {
	return await callChitChattrApi(`/CustomerPortal/ConfirmMsSubscription?code=${apiCodes.ConfirmMsSubscription}&productName=${productName}&msSubscriptionId=${msSubscriptionId}&planId=${planId}&quantity=${quantity}`, "GET", null, false);
}

export async function changeSubscriptionLevel (productName: string, subscriptionId: string, subscriptionLevel: SubscriptionLevel) {

	const updateInfo = {
		productName: productName,
		subscriptionId: subscriptionId,
		subscriptionLevel: subscriptionLevel
	};

	return await callChitChattrApi(`/CustomerPortal/ChangeSubscriptionLevel?code=${apiCodes.ChangeSubscriptionLevel}`, "POST", updateInfo);
}

export async function changeSubscriptionLicenseCount (productName: string, subscriptionId: string, newLicenseCount: number) {

	const updateInfo = {
		productName: productName,
		subscriptionId: subscriptionId,
		licenseCount: newLicenseCount
	};

	return await callChitChattrApi(`/CustomerPortal/ChangeSubscriptionLicenseCount?code=${apiCodes.ChangeSubscriptionLicenseCount}`, "POST", updateInfo);
}

export async function checkApiConsentConfirmed () {
	return await callChitChattrApi(`/CustomerPortal/CheckApiConsentConfirmed?code=${apiCodes.CheckApiConsentConfirmed}`);
}

export async function checkProductConsentConfirmed (productName:string) {
	return await callChitChattrApi(`/CustomerPortal/CheckSSO?code=${apiCodes.CheckSSO}&productName=${productName}`);
}

export async function enableProductSSO (productName:string) {
	return await callChitChattrApi(`/CustomerPortal/EnableSSO?code=${apiCodes.EnableSsoProduct}&productName=${productName}`, "POST");
}