import React, { useState, useEffect } from "react";
import {
	PrimaryButton,
} from '@fluentui/react';

import * as chitChattrApi from '../../services/chitchattrApiService';
import { IMsSubscription } from '../../types';
import { Loader } from '../../components';

import styles from '../../styles/wizard.module.scss';

// import quicklinksLogo from '../../assets/images/quicklinksLogoWithTitle.png';
import chitChattrLogo from '../../assets/images/chitChattrFooterLogoWhiteBg.png';
import quicklinksLogo from '../../assets/images/quicklinksLogoWithTitleInverted.png';

export function QuicklinksWelcomePage(props: {}) {

	const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
	const msSubscriptionToken = urlSearchParams.get("token") ?? '';

	const [isLoading, setIsLoading] = useState(true);
	const [msSubscriptionConfirmed, setMsSubscriptionConfirmed] = useState(false);
	const [msSubscriptionError, setMsSubscriptionError] = useState('');
	const [msSubscription, setMsSubscription] = useState<IMsSubscription | null>(null);
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		(async () => {
			if (msSubscriptionToken && isLoading && !msSubscription) {
				const subscriptionInfo = await chitChattrApi.getMsSubscription(msSubscriptionToken);
				console.log(subscriptionInfo);
				setMsSubscription(subscriptionInfo);
				setIsLoading(false);
				// window.setTimeout(() => {
				// 	setMsSubscription({
				// 		id:"123",
				// 		offerId:"quicklinks",
				// 		planId: "ql-pro",
				// 		quantity: '55',
				// 		subscriptionName:"123-54454-656565"
				// 	});
				// 	setIsLoading(false);
				// }, 2500);
			}
		})();
	}, [isLoading, msSubscription, msSubscriptionToken]);

	const confirmMsSubscription = async (): Promise<void> => {
		setIsSaving(true);
		(async () => {
			const subscription = (msSubscription as IMsSubscription);
			const msSubConfirmResponse = await chitChattrApi.confirmMsSubscription("Quicklinks", subscription.id, subscription.planId, subscription.quantity);

			if (msSubConfirmResponse.success)
			{
				setMsSubscriptionConfirmed(true);
				document.location.href = "/QuicklinksConsent?w=t&p=" + subscription.id;
			}
			else
				setMsSubscriptionError(msSubConfirmResponse.errorMessage);
		})();
	}

	return (
		<>
		<div className={styles.leftSidePanel} style={{justifyContent: "center"}}>
			
			{!msSubscriptionToken && (
				<>No token was received from Microsoft AppSource, cannot continue. Please go to AppSource and try again.</>
			)}
	
			{msSubscriptionToken && (
				<>
					<img alt="ChitChattr Logo" src={chitChattrLogo} style={{width: "180px", paddingBottom: "3%"}} />

					<h1>Thank you for joining Quicklinks!</h1>
					{isLoading && (
						<div style={{height: "10%", width: "fit-content"}}>
							<Loader />
							<p>Verifying subscription information...</p>
						</div>
					)}

					{!isLoading && msSubscription && (
						<>
						<p>
							Congratulations, you have purchased <b>{msSubscription.quantity} seats</b> on our <b>{msSubscription.planId.indexOf("ent") ? "Enterprise" : "Pro"} plan</b> and with this subscription &nbsp;
							{msSubscription.planId.indexOf("Ent")
							? <>you can create, manage and share private and shared Quicklinks.</>
							: <>you’ll have access to even more personal and organisational features.</>}
						</p>
						<p>
							Please complete your registration below to confirm  and begin configuring your subscription.
						</p>

							{/* <table>
								<tr><th>Offer</th><td>{msSubscription.offerId}</td></tr>
							</table> */}
						</>
					)}

							{msSubscriptionError && (
								<div>{msSubscriptionError}</div>
							)}

							<div className={styles.welcomeButton}>
								<PrimaryButton
									text="Complete registration now"
									onClick={confirmMsSubscription}
									disabled={isLoading || isSaving || msSubscriptionConfirmed}
									/>
							</div>
						
							{msSubscriptionConfirmed && (
								<div>Redirecting...</div>
							)}
				</>
			)}

		</div>
		<div className={styles.rightSidePanel}>
			<img alt="Quicklinks Logo" src={quicklinksLogo} style={{width: "clamp(47px, 50%, 50%)"}} />
		</div>
		</>
	)
}