import React, { useState, useEffect } from 'react';
import { Person, PersonCardInteraction, PersonViewType, PeoplePicker, PersonType, UserType } from '@microsoft/mgt-react';
import { ICcSubscription, LicenseStatus, PeopleEditorPeopleType } from '../types';

import { IconButton, SearchBox } from '@fluentui/react';

import * as Helpers from '../utils/helpers';

import styles from '../styles/shared.module.scss';

export interface PeopleListProps {
	editorType: PeopleEditorPeopleType;
	subscription: ICcSubscription;
	saveChanges: (subscription: ICcSubscription) => Promise<void>;
	saving: boolean;
	disabled: boolean;
}

export const PeopleList: React.FC<PeopleListProps> = props => {

	const totalLicenseCount = props.subscription?.numberOfUsersLicensed ?? 0;
	const currentLicenseCount = props.subscription?.users ? props.subscription?.users.length : 0;

	useEffect(() => {
		setUserGroup(props.editorType === PeopleEditorPeopleType.User ? props.subscription?.users : props.subscription?.admins);
	}, [props.editorType, props.subscription]);

	const [userGroup, setUserGroup] = useState(props.editorType === PeopleEditorPeopleType.User ? props.subscription?.users : props.subscription?.admins);

	const [searchTerm, setSearchTerm] = useState('');
	const [usersToShow, setUsersToShow] = useState(userGroup);

	useEffect(() => {
		(async () => {
			const users = await Helpers.resolveAndSortNames(userGroup);
			const lowerSearchTerm = searchTerm.toLocaleLowerCase();
	
			setUsersToShow(lowerSearchTerm ? users.filter(user => user.userName ? user.userName.toLocaleLowerCase().indexOf(lowerSearchTerm) > -1 : false) : users);
		})();
	}, [searchTerm, userGroup])

	const pickerChanged = async (e: Event): Promise<void> => {
		const customEvents = (e as any);
		if (e && customEvents.detail && customEvents.detail.length) {
			const personData = (e as any).detail[0];
			if (props.subscription) {

				let subscription = JSON.parse(JSON.stringify(props.subscription)) as ICcSubscription; // clone

				if (props.editorType === PeopleEditorPeopleType.User)
				{
					if (!props.subscription.users.find(u => u.userId === personData.id)) {
						subscription.users.push({ userId: personData.id, userName: personData.displayName });
						subscription.users = await Helpers.resolveAndSortNames(subscription.users);
					}
				} else 
				{
					if (!props.subscription.admins.find(a => a.userId === personData.id)) {
						subscription.admins.push({ userId: personData.id, userName: personData.displayName });
						subscription.admins = await Helpers.resolveAndSortNames(subscription.admins);
					}
				}

				await props.saveChanges(subscription);
			}
		}
	}

	const removeUser = async (personId: string): Promise<void> => {
		if (props.subscription) {
			let subscription = JSON.parse(JSON.stringify(props.subscription)) as ICcSubscription; // clone
			if (props.editorType === PeopleEditorPeopleType.User)
			{
				subscription.users = subscription.users.filter(user => user.userId !== personId);
			} else
			{
				subscription.admins = subscription.admins.filter(admin => admin.userId !== personId);
			}

			await props.saveChanges(subscription);
		}
	}

	const search_Change = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) : void => {
		setSearchTerm(newValue as string);
	}

	return (
		<>
			<div className={styles.peopleListHeader}>
				<h3 style={{fontSize: "1.2rem"}}>
					{props.editorType === PeopleEditorPeopleType.User ? "Teammates" : "Administrators"} &nbsp;
					{props.editorType === PeopleEditorPeopleType.User ? `(${currentLicenseCount} of ${totalLicenseCount})` : "" }
				</h3>
				<div>
					<SearchBox onChange={search_Change} />
				</div>
			</div>
			<div className={styles.peopleItemListContainer}>
				{usersToShow.map(user =>
					<div key={`${props.editorType}-${user.userId}`} className={styles.peopleItemContainer}>
						<Person
							userId={user.userId}
							view={PersonViewType.oneline}
							personCardInteraction={PersonCardInteraction.hover}>
							{/* <PersonRowTemplate template='default'/> */}
						</Person>
						<IconButton
								iconProps={{ iconName: "Delete" }}
								onClick={() => removeUser(user.userId)}
								disabled={props.saving || props.subscription.licenseStatus !== LicenseStatus.Active} />
					</div>
				)}
			</div>
			<div>
				{/* {props.showAdd && ( */}
					<PeoplePicker
						type={PersonType.person}
						selectedPeople={[]}
						userType={UserType.user}
						selectionMode='single'
						placeholder={`+ start typing to add ${props.editorType === PeopleEditorPeopleType.User ? "a teammate" : "an administrator"}`}
						disabled={props.disabled || (props.editorType === PeopleEditorPeopleType.User && (totalLicenseCount - currentLicenseCount) <= 0) || props.subscription.licenseStatus !== LicenseStatus.Active}
						selectionChanged={pickerChanged} />
				{/* )} */}
			</div>
		</>
	);
};

/*
detail
: 
Array(1)
0
: 
businessPhones
: 
[]
displayName
: 
"Patti Fernandez"
givenName
: 
"Patti"
id
: 
"2d0"
jobTitle
: 
null
mail
: 
"PattiF@M365x2..3.OnMicrosoft.com"
mobilePhone
: 
null
officeLocation
: 
null
preferredLanguage
: 
null
surname
: 
"Fernandez"
userPrincipalName
: 
"PattiF@M365x2..3.OnMicrosoft.com"
*/