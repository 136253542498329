import React, { useState } from "react";
import {
	TextField,
} from '@fluentui/react';

import { WizardStateManager } from '..';

import { SubscriptionStepWizardChildProps } from '../../types';

import styles from '../../styles/wizard.module.scss';

export const SubscriptionNameStep : React.FC<Partial<SubscriptionStepWizardChildProps>> = ({ ...props }) => {
// export const LicenseAssignmentTypeStep = (props:any) => {

	const [label, setLabel] = useState(props.subscription?.label);

	const subscriptionNameChange = async () : Promise<void> => {
		let subscription = props.subscription;
		if (subscription && props.saveChanges && props.subscription?.label !== label)
		{
			subscription.label = label ?? '';
			await props.saveChanges(subscription);
		}
	}

	return (
		<div className={styles.wizardStep}>
			<div>
				<h3>What would you like to call this Subscription?</h3>

				<div className={styles.subscriptionNameOuterContainer}>
					
					<span>Your organization already has paid Quicklinks subscriptions so it would be good to give this one a unique name to differentiate it from the others</span>
						
					<div className={styles.wizardTextboxContainer}>
						<TextField
							disabled={props.saving}
							placeholder="New subscription name"
							value={label}
							onChange={(e, newValue) => setLabel(newValue)}
							onBlur={subscriptionNameChange} />
					</div>
				</div>
			</div>

            <WizardStateManager {...props} />
		</div>
	)
}