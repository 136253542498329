import React from 'react';

import styles from './wizardNavigator.module.scss';

export const WizardNavigator : React.FC<{}> = (props:any) => {
	// const dots = [];
    // for (let i = 1; i <= props.totalSteps; i += 1) {
    //     const isActive = props.currentStep === i;
    //     dots.push((
    //         <span
    //             key={`step-${i}`}
    //             className={`${styles.dot} ${isActive ? styles.active : ''}`}
    //             onClick={() => props.goToStep(i)}
    //         >&nbsp;</span>
    //     ));
    // }

	// https://stackoverflow.com/questions/3746725/how-to-create-an-array-containing-1-n
	const blankArray = Array.from({length: props.totalSteps}, (_, i) => i + 1);

    return (
        <div className={styles.nav}>
			{blankArray.map((step:any, index:number) => 
				<span
					key={`step-${index}`}
					className={`${styles.dot} ${props.currentStep === index  + 1 ? styles.active : ''} ${props.currentStep > index ? styles.visited : ''} `}
					onClick={() => props.goToStep(index)}
				>&nbsp;</span>
			)}
		</div>
    );
};