import React, { useState } from "react";
import {
	Label,
	MessageBar, MessageBarType,
	Panel,
	PrimaryButton,
	TextField,
} from '@fluentui/react';

import { ICcSubscription, MsProviderSkus } from '../types';

import styles from '../styles/shared.module.scss';

interface ChangeUserCountPanelProps {
	subscription: ICcSubscription,
	hidePane:  (newUserCount: number | null) => Promise<void>
}

export const ChangeUserCountPanel: React.FC<ChangeUserCountPanelProps> = props => {

	const minUserCount = props.subscription.providerSku === MsProviderSkus.Ent250 || props.subscription.providerSku === MsProviderSkus.Pro250 ? 1
				: props.subscription.providerSku === MsProviderSkus.Ent500 || props.subscription.providerSku === MsProviderSkus.Pro500 ? 250
				: 500;

	const maxUserCount = props.subscription.providerSku === MsProviderSkus.Ent250 || props.subscription.providerSku === MsProviderSkus.Pro250 ? 250
				: props.subscription.providerSku === MsProviderSkus.Ent500 || props.subscription.providerSku === MsProviderSkus.Pro500 ? 500
				: 1000;

	const [currentLicenseUserCount, setCurrentLicenseUserCount] = useState(props.subscription.numberOfUsersLicensed);
	const [error, setError] = useState("");

	const licenseCount_Change = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) : void => {
		const newCount = parseInt(newValue as string);
		// if (newCount >= minUserCount && newCount <= maxUserCount)
		setCurrentLicenseUserCount(newCount);
	
		if (newCount < props.subscription.users.length)
			setError("You cannot decrease the number of users to below the current number of users actually licensed. Please delete some user licenses before decreasing this count.");
		else if (newCount < minUserCount)
			setError("You cannot currently decrease the number of users to below the count of your current subscription level in this portal. Please see Note 2 below.");
		else if (newCount > maxUserCount)
			setError("You cannot currently increase the number of users to above the count of your current subscription level in this portal. Please see Note 2 below.");
		else
			setError("");
	}

	const changeLicenseCountButtonClicked = () => {
		props.hidePane(currentLicenseUserCount);
	}

	const cancel = () => {
		props.hidePane(null);
	}

	return (
		<Panel
			headerText={"Change License Count?"}
			isOpen={true}
			onDismiss={cancel}
			isLightDismiss={true}
			closeButtonAriaLabel="Close">

			<div className={styles.row}>
				<Label>New user count:</Label>
				<TextField
					value={currentLicenseUserCount.toString()}
					onChange={licenseCount_Change}
					type="number"
					min={Math.max(props.subscription.users.length, minUserCount)} // choose the larger because the user might be below the minimum count of the current band
					max={maxUserCount}
				/>
				{error && (
					<div style={{ color: "red" }}>{error}</div>
				)}
			</div>

			<div className={styles.row}>
				<PrimaryButton
					disabled={error !== "" || currentLicenseUserCount === props.subscription.users.length || !currentLicenseUserCount}
					onClick={changeLicenseCountButtonClicked}>Change License Count</PrimaryButton>
			</div>

			<div className={styles.row} style={{paddingTop: "20px"}}>
				<MessageBar messageBarType={MessageBarType.info} className={styles.consentMessageBar}>
					<span style={{fontSize: "1rem", lineHeight: "1.3rem"}}>
						<b>PLEASE NOTE!</b><br/>
						Changing the number of users on your license requires us to communicate with Microsoft services in order to ensure that the billing information is updated accordingly. This will take a few moments to complete so you will be notified by email when it is complete.
					</span>
				</MessageBar>
			</div>

			<div className={styles.row} style={{paddingTop: "20px"}}>
				<MessageBar messageBarType={MessageBarType.info} className={styles.consentMessageBar}>
					<span style={{fontSize: "1rem", lineHeight: "1.3rem"}}>
						<b>PLEASE NOTE 2!</b><br/>
						You current plan allows for a minimum of {minUserCount} and a maximum of {maxUserCount} users. Changing to an amount below or above these limits is currently not possible in this portal and will require your administrator to change the plan and user count in the <a target="_blank" rel="noreferrer" href={"https://admin.microsoft.com/adminportal/home?#/subscriptions/assets/" + props.subscription.purchaseId}>Microsoft 365 admin center</a>.
					</span>
				</MessageBar>
			</div>
		</Panel>
	)
}