import { IUser } from '../types';

export const nameSort = (a:IUser, b: IUser) : number => {
	if (a.userName > b.userName) return 1;
	if (b.userName > a.userName) return -1;
	else return 0;
}

export const resolveAndSortNames = async (users: IUser[]) : Promise<IUser[]> => {
	return new Promise (function(resolve) {

		let mgtUsersDb : IDBDatabase;
		indexedDB.open('mgt-users').onsuccess = (dbOpenEvent) => {
			if (dbOpenEvent && dbOpenEvent.target)
			{
				mgtUsersDb = (dbOpenEvent.target as any).result;
				const objectStore = mgtUsersDb.transaction("users").objectStore("users");

				let promises: Promise<unknown>[] = [];

				for (let i = 0; i < users.length; i++) {
					let user = users[i];
					
					const userPromise = new Promise (function(userResolve) {
						objectStore.get(user.userId).onsuccess = (event) => {
							const dbUserResult = (event.target as any).result;
							if (dbUserResult)
							{
								const dbUserJson = dbUserResult.user;
								const dbUser = JSON.parse(dbUserJson);

								user.userName = dbUser.displayName;
							}
							
							return userResolve(0);
						};
					});

					promises.push(userPromise);
				}
			
				Promise.all(promises).then(() => {
					users = users.sort(nameSort);

					return resolve(users);
				});
			}
		};
	});
}