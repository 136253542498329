import React from "react";

import { ICcSubscription, PeopleEditorPeopleType, SubscriptionStepWizardChildProps } from '../../types';
import { WizardStateManager, WizardPeopleList } from '..';

import styles from '../../styles/wizard.module.scss';

export const AssignUsersStep : React.FC<Partial<SubscriptionStepWizardChildProps>> = ({ ...props }) => {

	return (
		<div className={styles.wizardStep}>
			<div>
				<WizardPeopleList
					editorType={PeopleEditorPeopleType.User}
					subscription={props.subscription as ICcSubscription}
					saveChanges={props.saveChanges as any}
					saving={props.saving as boolean}
					/>
			</div>

            <WizardStateManager {...props} />
		</div>
	)
}