import React from "react";
import { Icon, PrimaryButton } from "@fluentui/react";

import { WizardStateManager } from '..';

import { SubscriptionStepWizardChildProps } from '../../types';

import styles from '../../styles/wizard.module.scss';

export const ThankYouStep : React.FC<Partial<SubscriptionStepWizardChildProps>> = ({ ...props }) => {

	const goToPortal = () : void => {
		document.location = "QuicklinksSubscriptionAdmin?s=" + props.subscription?.sId;
	}

	return (
		<div className={styles.wizardStep}>

			<div className={styles.thankYouOuterContainer}>

				<div style={{ display: "flex", alignItems: "center"}} className={styles.success500}>
					<div>
						<Icon iconName="CircleRing" style={{fontSize: "4rem"}} /> 
					</div>
					<div style={{marginLeft: "-67px"}}>
						<Icon iconName="StatusCircleCheckmark" style={{fontSize: "4.5rem", lineHeight: "2.9rem"}} />
					</div>
				</div>

				<h3>Setup complete!</h3>

				<span>Thank you for setting up your organization's Quicklinks.</span>
				<br/>
				<span>You are done with this quick setup wizard so you can now close this tab and go straight to using Quicklinks or you can go on to the regular portal to make any further changes.</span>
			
				<div style={{paddingTop: "45px"}}>
					<PrimaryButton onClick={goToPortal}>Go to the Regular Portal</PrimaryButton>
				</div>
			</div>

            <WizardStateManager {...props} />
		</div>
	)
}