import React from "react";

import styles from '../../styles/wizard.module.scss';

interface IWizardLayoutProps extends React.HTMLAttributes<HTMLElement> {
	showFooter: boolean;
	helpUrl?: string;
	children: React.ReactNode;
}

export const WizardLayout: React.FC<IWizardLayoutProps> = (props) => {

	const helpUrl = props.helpUrl ?? "https://docs.chitchattr.com/docs/category/quicklinks";

	return (
		<div className={styles.wizardOuterContainer}>
			
			<div className={styles.wizardContainer}>
				{props.children}
			</div>

			{props.showFooter && (
				<div className={styles.wizardFooter}>
					<div>
						<img alt="ChitChattr Logo - light background" src={`${process.env.REACT_APP_ASSETS_URLBASE}/images/ChitChattrFooterLogo-WhiteBg.png?v=1`} />

						<div className={styles.footerMadeByText}>
							made by &nbsp;
						</div>

						<a href="https://www.chitchattr.com" target="_blank" rel="noreferrer">
							ChitChattr
						</a>
					</div>

					<div>
						<div className={styles.footerHelpLink}><a href={helpUrl} target="_blank" rel="noreferrer">Get help</a></div>
					</div>
				</div>
			)}

		</div>
	);
}