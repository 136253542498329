import React from "react";
// import {
// 	IconButton,
// } from '@fluentui/react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

// import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
// import { InteractionStatus, InteractionType, InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";
// import { loginRequest } from "../configuration/authConfig";

// import styles from '../../shared.module.scss';

export function SubscriptionWelcomePage(props: {}) {

	return (
		<div>

			<UnauthenticatedTemplate>
				Warning message here about logging in...
			</UnauthenticatedTemplate>

			<AuthenticatedTemplate>
				Authenticated...
			</AuthenticatedTemplate>

			<div> 
				<div>Accordian Wizard...</div>

				<div> 
					<div>Subscription stuff</div>
				</div>

				<div> 
					<div>License Users stuff</div>

					<UnauthenticatedTemplate>
						Warning message here about logging in...
					</UnauthenticatedTemplate>

					<AuthenticatedTemplate>
						User license type option...
						{/* <PeopleList /> */}
						<div>PeopleList goes here...</div>
					</AuthenticatedTemplate>
				</div>

			</div>

		</div>
	)
}