import React from 'react';
import { createRoot } from 'react-dom/client';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { MgtPersonCard } from '@microsoft/mgt-react';
import { Providers } from '@microsoft/mgt-element';
// import { Msal2Provider } from '@microsoft/mgt-msal2-provider';

import { ChitChattrProvider } from './configuration/chitChattrProvider';

import reportWebVitals from './reportWebVitals';

import App from './App';

// MSAL imports
import * as authConfig from "./configuration/authConfig";

export const msalInstance = new PublicClientApplication(authConfig.msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
	msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
	// console.log(event);

	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
		const payload = event.payload as AuthenticationResult;
		const account = payload.account;
		msalInstance.setActiveAccount(account);
	}
	// else if (event.eventType === EventType.HANDLE_REDIRECT_END && currentActiveAccount)
	// {
	// 	// Providers.globalProvider.setState(ProviderState.Loading);

	// 	// msalInstance.acquireTokenSilent(authConfig.silentLoginRequest)
	// 	// .then(result => {
	// 	// 	Providers.globalProvider = new SimpleProvider((scopes: string[]) => {
	// 	// 		return Promise.resolve(result.accessToken);
	// 	// 	});

	// 	// 	Providers.globalProvider.setState(ProviderState.SignedIn);
	// 	// });
	// }
});

// const simpleProvider = new SimpleProvider(async (scopes: string[]) => { // getAccessTokenHandler

// 	const accounts = msalInstance.getAllAccounts();
// 	if (accounts.length > 0) {
// 		msalInstance.setActiveAccount(accounts[0]);
// 	}

// 	const finalGraphScopes = scopes ?? authConfig.mgtPeoplePickerScopes;

// 	try {
// 		let response = await msalInstance.acquireTokenSilent({ scopes: finalGraphScopes });
// 		return response.accessToken;
// 	} catch (error) {
// 		console.log("globalProvider - MsGraph: " + error);
// 		// if (error instanceof InteractionRequiredAuthError) {
// 		// 	msalInstance.acquireTokenRedirect({
// 		// 		scopes: scopes,
// 		// 		account: msalInstance.getActiveAccount() as AccountInfo
// 		// 	});
// 		// }
// 	}

// 	return "";
// }, async () => {

// 	const accounts = msalInstance.getAllAccounts();
// 	if (accounts.length === 0) {
// 		msalInstance.acquireTokenRedirect({
// 			scopes: authConfig.minimalApiScopes,
// 			// account: msalInstance.getActiveAccount() as AccountInfo
// 		});
// 	}

// 	try {
// 		await msalInstance.acquireTokenSilent({ // try sign in to CC consents first
// 			scopes: authConfig.minimalApiScopes
// 		});
// 	} catch (error) {
// 		if (error instanceof InteractionRequiredAuthError) {
// 			msalInstance.acquireTokenRedirect({
// 				scopes: authConfig.minimalApiScopes,
// 				account: msalInstance.getActiveAccount() as AccountInfo
// 			});
// 		}
// 	}

// 	try {
// 		const loginResponse = await msalInstance.acquireTokenSilent({ // then make sure we have Graph consents
// 			scopes: authConfig.mgtPeoplePickerScopes
// 		});

// 		console.log(loginResponse);
// 	} catch (error) {
// 		if (error instanceof InteractionRequiredAuthError) {
// 			msalInstance.acquireTokenRedirect({
// 				scopes: authConfig.mgtPeoplePickerScopes,
// 				account: msalInstance.getActiveAccount() as AccountInfo
// 			});
// 		}
// 	}

// 	// const accounts2 = msalInstance.getAllAccounts();
// 	// if (accounts2.length > 0) {
// 	// 	msalInstance.setActiveAccount(accounts2[0]);
// 	// }

// 	// this.setState(ProviderState.SignedIn);
// 	Providers.globalProvider.setState(ProviderState.SignedIn);
// 	// document.location.reload();

// }, async () => {
// 	// Clear "me" saved object, then log out
// 	let mgtUsersDb : IDBDatabase;
// 	indexedDB.open('mgt-users').onsuccess = (dbOpenEvent) => {
// 		if (dbOpenEvent && dbOpenEvent.target)
// 		{
// 			mgtUsersDb = (dbOpenEvent.target as any).result;
// 			const objectStore = mgtUsersDb.transaction("users", "readwrite").objectStore("users");

// 			objectStore.delete("me");

// 			msalInstance.logoutRedirect();
// 		}
// 	};
// });

// Providers.globalProvider = simpleProvider;
Providers.globalProvider = new ChitChattrProvider(authConfig.msalConfig, msalInstance);
// Providers.globalProvider.setState(ProviderState.SignedIn);

MgtPersonCard.config.sections.files = false;
MgtPersonCard.config.sections.mailMessages = false;
MgtPersonCard.config.sections.profile = false;
MgtPersonCard.config.sections.organization = false;

// Providers.globalProvider = new Msal2Provider({
// 	clientId: authConfig.msClientId,
// 	publicClientApplication: msalInstance,
// 	options: {
// 		auth: authConfig.msalConfig.auth
// 	}
// });
// Providers.globalProvider = new SimpleProvider(async (scopes: string[]) =>  {
// 	const tokenResponse = await msalInstance.handleRedirectPromise();
// 	// return a promise with accessToken
// 	if (tokenResponse)
// 		return Promise.resolve(tokenResponse?.accessToken);
// 	else
// 		return '';
// });
// Providers.globalProvider.setState(ProviderState.SignedIn);

// set state to signal to all components to start calling graph

// ReactDOM.render(
// 	<React.StrictMode>
// 		<App publicClientApplication={msalInstance} />
// 	</React.StrictMode>,
// 	document.getElementById('root')
// );

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
	// <React.StrictMode>
	<App publicClientApplication={msalInstance} />
	// </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();