import React from 'react';
import {
	IconButton
} from '@fluentui/react';
import { Person, PersonCardInteraction, PersonViewType, PeoplePicker, PersonType, UserType } from '@microsoft/mgt-react';

import * as Helpers from '../../utils/helpers';
import { ICcSubscription, PeopleEditorPeopleType } from '../../types';

import styles from '../../styles/wizard.module.scss';

export interface WizardPeopleListProps {
	editorType: PeopleEditorPeopleType;
	subscription: ICcSubscription;
	saveChanges: (subscription: ICcSubscription) => Promise<void>;
	saving: boolean;
}

export const WizardPeopleList: React.FC<WizardPeopleListProps> = props => {

	const totalLicenseCount = props.subscription?.numberOfUsersLicensed ?? 0;
	const currentLicenseCount = props.subscription?.users ? props.subscription?.users.length : 0;

	const userGroup = props.editorType === PeopleEditorPeopleType.User ? props.subscription?.users : props.subscription?.admins;

	const pickerChanged = async (e: Event): Promise<void> => {
		const customEvents = (e as any);
		if (e && customEvents.detail && customEvents.detail.length) {
			const personData = (e as any).detail[0];
			if (props.subscription) {

				let subscription = JSON.parse(JSON.stringify(props.subscription)) as ICcSubscription; // clone

				if (props.editorType === PeopleEditorPeopleType.User)
				{
					if (!props.subscription.users.find(u => u.userId === personData.id)) {
						subscription.users.push({ userId: personData.id, userName: personData.displayName });
						subscription.users = await Helpers.resolveAndSortNames(subscription.users);
					}
				} else 
				{
					if (!props.subscription.admins.find(a => a.userId === personData.id)) {
						subscription.admins.push({ userId: personData.id, userName: personData.displayName });
						subscription.admins = await Helpers.resolveAndSortNames(subscription.admins);
					}
				}

				await props.saveChanges(subscription);
			}
		}
	}

	const removeUser = async (personId: string): Promise<void> => {
		if (props.subscription) {
			let subscription = JSON.parse(JSON.stringify(props.subscription)) as ICcSubscription; // clone
			if (props.editorType === PeopleEditorPeopleType.User)
			{
				subscription.users = subscription.users.filter(user => user.userId !== personId);
			} else
			{
				subscription.admins = subscription.admins.filter(admin => admin.userId !== personId);
			}

			await props.saveChanges(subscription);
		}
	}

	return (
		<div className={styles.subscriptionUsersOuterContainer}>

			<div>
				<h3>Add your {props.editorType === PeopleEditorPeopleType.User ? "teammates" : "administrators"}</h3>

				
					{
						props.editorType === PeopleEditorPeopleType.User
							? <>
								<span>Search for members of your team here to assign licenses to them.</span><br/>
								<span style={{color: (props.editorType === PeopleEditorPeopleType.User && (totalLicenseCount - currentLicenseCount) > 0) ? "" : "darkorange"}}>You have <b>{totalLicenseCount - currentLicenseCount}</b> license{totalLicenseCount - currentLicenseCount !== 1 ? "s" : ""} remaining.</span>
							</>
							: <>
								<span>Search for colleagues you would like to be able to administer this subscription (e.g. adding and removing users).</span><br/>
								{props.subscription?.admins.length === 1 && (
									<span style={{color: "darkorange"}}>It is strongly advised to have at least one additional administrator as a backup.</span>
								)}
							</>
					}
				

				<div style={{ paddingTop: "30px" }}>
					<PeoplePicker
						type={PersonType.person}
						selectedPeople={[]}
						userType={UserType.user}
						selectionMode='single'
						disabled={props.saving || (props.editorType === PeopleEditorPeopleType.User && (totalLicenseCount - currentLicenseCount) <= 0)}
						placeholder={props.editorType === PeopleEditorPeopleType.User ? "Search for teammates" : "Search for administrators"}
						selectionChanged={pickerChanged} />
				</div>
			</div>

			<div>
				<h4>
					Added {props.editorType === PeopleEditorPeopleType.User ? "teammates" : "administrators"}
					{props.editorType === PeopleEditorPeopleType.User && (<>&nbsp;({currentLicenseCount} of {totalLicenseCount})</>)}
				</h4>

				<div className={styles.currentPeopleContainer}>
					{userGroup.map(user =>
						<div key={`${props.editorType}-${user.userId}`} className={styles.subscriptionUsersItem}>
							<Person
								userId={user.userId}
								view={PersonViewType.oneline}
								personCardInteraction={PersonCardInteraction.hover}>
								{/* <PersonRowTemplate template='default'/> */}
							</Person>
							<IconButton
								iconProps={{ iconName: "Delete" }}
								onClick={() => removeUser(user.userId)}
								disabled={props.saving} />
						</div>
					)}
				</div>
			</div>

		</div>
	);
};

/*
detail
: 
Array(1)
0
: 
businessPhones
: 
[]
displayName
: 
"Patti Fernandez"
givenName
: 
"Patti"
id
: 
"2d0"
jobTitle
: 
null
mail
: 
"PattiF@M365x2..3.OnMicrosoft.com"
mobilePhone
: 
null
officeLocation
: 
null
preferredLanguage
: 
null
surname
: 
"Fernandez"
userPrincipalName
: 
"PattiF@M365x2..3.OnMicrosoft.com"
*/